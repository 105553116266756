<template>
    <div>
        <el-row :gutter="10" class="group">
            <el-col :sm="8">
                <div class="item">
                    <div class="clearfix">
                        <img class="fl" src="@/assets/img/finance/icon1.png">
                        <div class="fr tr">待结款<p>{{data && data.waitReceivablesAmount || 0.00}}</p></div>
                    </div>
                    <div class="tr MT10">演出结束后,<span class="color-info">您可以申请结款的金额<br/>以实际收入为基础但</span>未扣除服务费<span class="color-info"></span></div>
                </div>
            </el-col>
            <el-col :sm="8">
                <div class="item">
                    <div class="clearfix">
                        <img class="fl" src="@/assets/img/finance/icon2.png">
                        <div class="fr tr">结款中<p>{{data && data.receivablesAmount || 0.00}}</p></div>
                    </div>
                    <div class="tr color-info MT10">您已经提交申请结款的金额<br/>秀动正在处理中,请耐心等待</div>
                </div>
            </el-col>
            <el-col :sm="8">
                <div class="item">
                    <div class="clearfix">
                        <img class="fl" src="@/assets/img/finance/icon3.png">
                        <div class="fr tr">已结款<p>{{data && data.finishReceivablesAmount || 0.00}}</p></div>
                    </div>
                    <div class="tr MT10"><span class="color-info">已经打款给您的金额</span><br/>若存在服务费,则已经扣除</div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data(){
        return{
            data: null,
            dataLoading: false
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        getData(){
            this.dataLoading = true;
            this.$request({
                url: "/activity/receivables/getActivityAudi",
                method: "POST",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        if(result.status == 2){
                            this.check();
                        }
                    } else {
                        this.$message.error(msg || "获取统计信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取统计信息错误");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        check(){
            this.$confirm('您的账户缺少有效的演出收款信息,无法申请结款', '提示', {
                type: 'warning',
                showClose:false,
                showCancelButton:false,
                confirmButtonText:'管理演出收款信息',
                closeOnClickModal:false,
                closeOnPressEscape:false,
                closeOnHashChange:false,
                // center:true
            }).then( () => {
                this.$router.push({
                    path:'/manage/finance/activity/receivables'
                })
            }).catch( () => {})
        }
    }
}
</script>

<style lang="scss" scoped>
    .group{
        .item{
            padding: 24px;
            background: #FFFFFF;
            border-radius: 8px;
            img{
                width: 76px;
            }
            .tr{
                text-align: right;
                font-size: 13px;
                line-height: 18px;
                &.fr{
                    font-size: 16px;
                    line-height: 22px;
                    p{
                        font-size: 32px;
                        line-height: 45px;
                    }
                }
            }
        }
    }    
</style>