<template>
    <el-drawer
        v-if="activityData"
        :title="activityData.playStatus == 2 ? '结款明细' : '申请结款'"
        :visible.sync="drawerShow"
        :wrapperClosable="false"
        :destroy-on-close="true"
        size="auto"
        @close="close"
    >
        <div class="drawer-content">
            <div v-if="activityData" class="group">
                <div class="page-group-title">演出信息</div>
                <table class="info-table MT20" width="100%">
                    <tr>
                        <th>演出</th>
                        <td>
							<p>ID：{{activityData.id}}</p>
							<p>名称：{{activityData.title}}</p>
						</td>
                        <th>结款方</th>
                        <td>
							<p>ID：{{activityData.receivablesUserId}}</p>
							<p>名称：{{activityData.receivablesUserName}}</p>
						</td>
                    </tr>
                    <tr>
                        <th>销售</th>
                        <td class="color-danger">
							<p>销售总数：{{activityData.saleCount}}</p>
							<p>销售总额：{{activityData.saleAmount}}</p>
						</td>
                        <th>收入</th>
                        <td>
							<p>实际收入：{{activityData.incomeStr}}</p>
							<p>承担优惠：{{activityData.busDiscountAmountStr}}</p>
						</td>
                    </tr>
					<tr>
					    <th>退票</th>
					    <td class="color-danger">
							<p>退票数：{{activityData.refundCount}}</p>
							<p>退票手续费：{{activityData.refundFeeStr}}</p>
						</td>
					    <th>秀动服务费</th>
					    <td>
					        <p>收费方式：
					        	<span v-if="activityData.serviceFeeType == 0">不收取</span>
					        	<span v-if="activityData.serviceFeeType == 1">固定金额</span>
					        	<span v-if="activityData.serviceFeeType == 2">按比例收取<!-- ({{scope.row.serviceFeeRate}}%) --></span>
					        </p>
					        <p>服务费：{{activityData.serviceFeeStr}}</p>
					    </td>
					</tr>
                    <tr>
                        <th>大麦服务费</th>
                        <td>
							<p>收费比例：<span>{{activityData.damaiServiceFeeRatioStr}}</span></p>
							<p>服务费：{{activityData.damaiServiceFeeStr}}</p>
						</td>
						<th>抖音服务费</th>
						<td>{{activityData.byteMiniServiceFeeStr}}</td>
                    </tr>
					<tr>					   
					    <th>抖音CPS佣金</th>
					    <td>{{activityData.byteMiniCpsFeeView}}</td>
						<th>额外技术服务费总额</th>
						<td>{{activityData.technicalServiceFeeStr }}</td>
					</tr>
					<tr>
						<th>小红书小程序服务费</th>
						<td>{{activityData.redBookletServiceFeeStr }}</td>
						<th>小红书小程序CPS佣金</th>
						<td>{{activityData.redBookletCpsFeeView }}</td>
					</tr>
                </table>
            </div>
            <div v-if="activityData.technicalServiceFees && activityData.technicalServiceFees.length > 0" class="group MT20">
                    <div class="page-group-title">额外技术服务费明细（总金额{{activityData.technicalServiceFeeStr}}）</div>
                    <table class="info-table MT20" width="100%">
                        <tr v-for="(item,index) in activityData.technicalServiceFees" :key="index">
                            <th>{{item.item}}</th>
                            <td>{{item.amount}}</td>
                        </tr>
                    </table>
                </div>
			<div v-if="receivablesInfo" class="group MT20">
			    <div class="page-group-title">收款信息</div>
			    <table class="info-table MT20" width="100%">
			        <tr>
			            <th>收款联系人姓名</th>
			            <td>{{receivablesInfo.userName}}</td>
						<th>收款人联系手机</th>
						<td>{{receivablesInfo.userTelephone}}</td>			           
			        </tr>
					<tr>
						<th>收款人联系邮箱</th>
						<td>{{receivablesInfo.userEmail}}</td>
						<th>通讯地址</th>
						<td>{{receivablesInfo.address}}</td>
					</tr>
					<tr>
						<th>银行</th>
						<td>{{receivablesInfo.bankName}}</td>
						<th>开户网点</th>
						<td>{{receivablesInfo.bankAddress}}</td>
					</tr>
			        <tr>
			           <th>卡号</th>
			           <td>{{receivablesInfo.bankCardId}}</td>
			            <th>账户名</th>
			            <td>{{receivablesInfo.bankAccount}}</td>						
			        </tr>
			        <tr>
			            <th>账户性质</th>
			            <td>{{receivablesInfo.accountType == 1 ? '企业' : '个人'}}</td>
			            <th>合同编号</th>
			            <td>{{receivablesInfo.contractSn}}</td>
			        </tr>
			    </table>
			</div>
            <div v-if="receivablesAudiInfo" class="group MT20">
                <div class="page-group-title">打款信息</div>
                <table class="info-table MT20" width="100%">
                    <tr>
                        <th>申请结款时间</th>
                        <td>{{receivablesAudiInfo.createTime}}</td>
                        <th>实际打款金额</th>
                        <td>{{receivablesAudiInfo.playPriceTicket}}</td>
                    </tr>
                    <tr>
                        <th>打款流水号</th>
                        <td>{{receivablesAudiInfo.playPriceNo}}</td>
                        <th>系统确认结款时间</th>
                        <td>{{receivablesAudiInfo.playTime}}</td>
                    </tr>
                    <tr>
                        <th>申请结款备注</th>
                        <td colspan="3">{{receivablesAudiInfo.applyDesc}}</td>
                    </tr>
                    <tr>
                        <th>打款截图</th>
                        <td colspan="3">
                            <div v-if="receivablesAudiInfo.playPic" class="img">
                                <el-image v-for="(item,index) in receivablesAudiInfo.playPic.split(',')" :key="index" :src="item" fit="cover" :preview-src-list="receivablesAudiInfo.playPic.split(',')"></el-image>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>打款备注</th>
                        <td colspan="3">{{receivablesAudiInfo.playDesc}}</td>
                    </tr>
                </table>
            </div>
            <!-- 结款状态 1：待打款，2：已打款，3：拒绝打款 -->
            <div v-if="activityData.playStatus ==1" class="group MT20">
                <div class="page-group-title">备注</div>
                <el-form
                    :model="formData"
                    ref="formData"
                    label-position="top"
                    class="MT20"
                >
                    <el-form-item prop="remark">
                        <el-input v-model.trim="formData.remark" type="textarea" :rows="2" maxlength="100" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>		
		<!--
		playStatus	结款状态 1：待打款，2：已打款，3：拒绝打款
		
		playPriceStatus	申请结款状态 1:已申请，0：未申请
		-->
        <div class="drawer-footer">
            <!-- <el-button v-if="activityData.playStatus == 2" type="primary" @click="close">确认</el-button> -->
			<el-button v-if="activityData.playPriceStatus == 0 && activityData.playStatus == 1" type="primary" @click="submitForm()" :loading="btnLoading">申请结款</el-button>
			<el-button @click="close">关闭</el-button>
        </div>
    </el-drawer>
</template>

<script>
export default {
    data(){
        return{
            drawerShow: false,
            btnLoading: false,
            formData:{
                remark:'',
                id:''
            },
            activityData: null,
            receivablesAudiInfo: null,
            receivablesInfo: null,
        }
    },
    methods:{
        close(){
            if(this.$refs.formData) this.$refs.formData.resetFields();
            this.receivablesAudiInfo = null;
            this.drawerShow = false;
        },
        show(activityData){
            this.activityData = activityData;
			
            if(this.activityData.playStatus == 2){ 
                this.getReceivablesAudiInfo(); //获取演出结款信息  {{scope.row.playStatus == 1 ? '未结款' : scope.row.playStatus == 2 ? '已结款' : '不可结款'}}
            }
			if(this.activityData.playStatus != 2){ 
				this.getReceivablesInfo(); //获取演出收款信息
			}
            
            this.drawerShow = true;
        },
        //获取演出收款信息  还没有结款的调这个接口    this.activityData.playStatus ！= 2
        getReceivablesInfo(){
            this.$request({
                url: "/activity/receivables/getReceivablesInfo",
                method: "POST",
                params:{id:this.activityData.recInfoId}
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.receivablesInfo = result;
                    } else {
                        this.$message.error(msg || "获取演出收款信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取演出收款信息错误");
                })
                .finally(() => {
                });
        },
        //获取演出结款信息  已经结了款的调这个接口    this.activityData.playStatus == 2
        getReceivablesAudiInfo(){
            this.$request({
                url: "/activity/receivables/getReceivablesAudiInfo",
                method: "POST",
                params:{id:this.activityData.audiId}
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.receivablesAudiInfo = result;
						this.receivablesInfo = result.receivablesInfoVO;
                    } else {
                        this.$message.error(msg || "获取演出结款信息失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取演出结款信息错误");
                })
                .finally(() => {
                });
        },
        //表单提交
        submitForm() {
            this.$refs["formData"].validate((valid) => {
                if (valid) {
                    const _url = "/activity/receivables/submitAudiInfo";
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        params: {...this.formData,id:this.activityData.id},
                        method: "post",
                    })
                        .then((res) => {
                            const { state, msg } = res.data;
                            if (state == 1) {
                                this.$message.success("提交成功");
                                this.$emit('onUpdate');
                                this.close();
                            } else {
                                this.$message.error(msg || "操作失败,请重试");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }else {
                    return false;
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.img{
    .el-image{
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
}
th{
    width: 130px;
    white-space: nowrap;
}
</style>