<template>
    <div>
        <Statistics ref="statistics"></Statistics>
        <List></List>
    </div>
</template>

<script>
import Statistics from './components/statistics';
import List from './components/list';
export default {
    components:{Statistics,List},
    data(){
        return{
            
        }
    },
    methods:{
        
    }
}
</script>